
















































































import CosmopolTextField from "@/components/CosmopolTextField.vue";
import {
  email,
  isPassword,
  passwordCheck,
  passwordLength,
  required,
} from "@/helpers/rules";
import { CBtn } from "@/models/utils/c-btn.interface";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component
export default class UserRoleCard extends Vue {
  @Prop({ required: true, type: Boolean })
  private isNewUser: boolean;
  @PropSync("userDescription", {
    required: false,
    default: null,
    type: String,
  })
  private _userDescription: string;
  @PropSync("email", {
    required: false,
    default: null,
    type: String,
  })
  private _email: string;
  @PropSync("userName", {
    required: false,
    default: null,
    type: String,
  })
  private _userName: string;
  @PropSync("password", {
    required: false,
    default: null,
    type: String,
  })
  private _password: string;
  @PropSync("confirmedPwd", {
    required: false,
    default: null,
    type: String,
  })
  private _confirmedPwd: string;
  @PropSync("pwdChanged", {
    required: true,
    type: Boolean,
  })
  private _pwdChanged: boolean;

  private required = required;
  private emailRule = email;
  private passwordLength = passwordLength;
  private passwordCheck = passwordCheck;
  private isPassword = isPassword;
  private newUserBtn: Omit<CBtn, "type"> = {
    btnClass: "c-btn-outlined",
    label: "Modifica password",
    labelClass: "c-label-dark",
    color: "var(--v-light-base)",
    icon: {
      name: "key",
      fill: "var(--v-primary-base)",
      height: 24,
      width: 24,
      iconStyle: "",
    },
  };

  private onModifyPwd(): void {
    this._pwdChanged = true;
    this._password = null;
    (this.$refs.pwd as CosmopolTextField).onClear();
  }

  private onClearPwd(): void {
    this._confirmedPwd = null;
    (this.$refs.confirmedPwd as CosmopolTextField).onClear();
  }
}
